<template>
    <div>
        <Quote/>
        <GoBack next="Kurs turini qo'shish"/>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-10 col-md-8">
                <form id="course-type " @submit.prevent="addCourseType">
                    <div class="my-4">
                        <label class="form-label" for="courseTypeName">Kurs turi</label>
                        <b-form-input
                            id="courseTypeName"
                            v-model="courseType.name"
                            class="form-control-input"
                            placeholder="Kurs turi"
                        >
                        </b-form-input>
                    </div>

                    <div class="my-4">
                        <label class="form-label" for="dependencyCourse">Qaram kurs turi</label>
                        <b-form-select
                            id="dependencyCourse"
                            v-model="courseType.dependencyCourse"
                            class="form-control form-control-select"
                            name="hero"
                        >
                            <option
                                v-for="courseTypes of getCourseTypes"
                                :key="courseTypes.id"
                                :label="courseTypes.name"
                                :value="'/api/course_types/' + courseTypes.id"
                            ></option>
                        </b-form-select>
                    </div>

                    <div class="my-4">
                        <label class="form-label" for="category">Kategoriya turi</label>
                        <b-form-select
                            id="category"
                            v-model="courseType.category"
                            class="form-control form-control-select"
                            name="hero"
                        >
                            <option
                                v-for="category of getCategories"
                                :key="category.id"
                                :label="category.name"
                                :value="'/api/categories/' + category.id"
                            >
                            </option>
                        </b-form-select>
                    </div>

                    <div class="my-4">
                        <label class="form-label" for="courseTypeName">Kurs davomiyligi</label>
                        <b-form-input
                            id="exampleInputEmail1"
                            v-model="courseType.duration"
                            aria-describedby="emailHelp"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Kurs davomiyligi"
                            step="0.01"
                            type="number"
                        >
                        </b-form-input>
                    </div>

                    <div class="row justify-content-end text-end mb-3">
                        <div class="col-12 col-sm-4 col-md-3,7">
                            <button-component>
                                Saqlash
                            </button-component>
                        </div>
                    </div>

                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Kurs turi muvaffaqiyatli qo'shildi</h3>
                            </div>
                            <b-button class="mt-3 float-end" size="lg" style="width: 100px"
                                      variant="btn btn-primary"
                                      @click="hideErrorModal">
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
    name: "Course_type",
    components: {ButtonComponent, GoBack, Quote},
    computed: {
        ...mapGetters(['getCourseTypes', "getCategories"])
    },
    methods: {
        ...mapActions(['pushCourseType', 'fetchCourseTypes', "fetchCategories"]),
        addCourseType() {
            this.pushCourseType(this.courseType)
                .then(() => {
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                })
                .catch(() => {
                    console.log('coursetype da xato')
                })
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$router.push('/add-course')
        },
    },
    data() {
        return {
            courseType: {
                name: "",
                category: null,
                dependencyCourse: null,
                duration: null,
            },
        }
    },
    mounted() {
        this.fetchCourseTypes()
        this.fetchCategories()
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

* {
    padding: 0;
    margin: 0;
}

.mb-3 .form-control-input {
    border-radius: 10px 0 0 10px;
    border: solid #80007F 1px;
    border-right: none !important;
    font-family: Comfortaa, cursive;
}

.form-check-input:focus {
    border-color: #80007F !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem #80007F !important;
}

.form-check-input:checked {
    background-color: #80007F !important;
    border-color: #80007F !important;
}

.mb-3 .form-control-select {
    border-radius: 0 10px 10px 0;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

.mb-3 .form-control {
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
    padding-top: 8px;
    padding-bottom: 8px;


}

.form-control {
    color: #000000;
    border-color: #80007F;
    padding-left: 1.5rem;
    border-radius: 10px;
}

#course-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    width: auto;
    height: 174px;
    left: 259.5px;
    top: 111px;
}

#cours-type {
    left: 0;
    top: 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 30px 0px;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #A6A6A6;
    background: #FFFFFF;
    border: 1px solid #80007F;
    box-sizing: border-box;
    border-radius: 10px;

}

#exampleInputEmail1 {
    border-radius: 10px;
    padding-left: 10px !important;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 6px #b505b4 !important;
}

.justify-content-end .btn-primary {
    text-align: center;
    padding: 5px 60px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

</style>